import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import * as _ from "lodash";
import { DataListComponent, DataListColumn, DataListOptions } from "@emasofts/common-vuejs-datalist";

import { Pagination as NekoPagination } from "@/components/pagination";
import { Select as NekoSelect, Input as NekoInput, Field as NekoField } from "@/components/form";
import { Dropdown as NekoDropdown } from "@/components/dropdown";

@Component({
	components: {
		NekoPagination,
		NekoSelect,
		NekoInput,
		NekoField,
		NekoDropdown
	}
})
export class DataList<T> extends DataListComponent<T> {
	public declare loading: boolean;
	public declare search: string;
	public declare columns: DataListColumn[];
	public declare options: DataListOptions;
	public declare elements: T[];
	public declare elementsTotal: number;

	public elementsSelected: T[] = [];

	@Ref()
	public readonly dataListContainer!: HTMLElement;

	@Ref()
	public readonly htmlElements!: HTMLElement[];

	@Prop({ type: Boolean })
	public readonly noAutoScroll!: boolean;

	public overflowContainerElement: HTMLElement | null = null;
	public containerScrollLeft: number = 0;
	public containerScrollTop: number = 0;

	private filtersInitialValue: Record<string, string | string[]> = {};

	public get actionsShown(): boolean {
		return this.selectShow || this.refreshShown || this.searchShown || !!this.$slots.actions?.length;
	}

	public get functionUniqueId() {
		return this.options.functionUnique || JSON.stringify;
	}

	public get infiniteScroll() {
		return this.options.infiniteScroll || false;
	}

	public get maxPage() {
		if (this.elementsTotal === 0) {
			return 1;
		}

		return Math.ceil(this.elementsTotal / this.limit);
	}

	public get currentPage() {
		return Math.ceil(this.start / this.limit) + 1;
	}

	public set currentPage(page: number) {
		this.start = (page - 1) * this.limit;

		this.refresh().then(() => {
			if (!this.noAutoScroll && this.htmlElements?.length > 0) {
				const positionFirstLine = this.htmlElements[0].getBoundingClientRect();

				document.querySelector(".app-container")?.scrollTo({
					top: positionFirstLine.top,
					left: positionFirstLine.left,
					behavior: "smooth"
				});
			}
		});
	}

	public get end() {
		const end = this.start + this.limit;

		return end > this.elementsTotal ? this.elementsTotal : end;
	}

	public get elementsGroupByRow() {
		return _.chunk(this.elements, this.elementsPerLine);
	}

	public get optionsShown(): boolean {
		return this.filtersShown || this.ordersShown || this.actionsShown;
	}

	public get ordersShown(): boolean {
		if (!this.table) {
			const ordersShow = this.options.ordersShow !== undefined ? this.options.ordersShow : true;
			return ordersShow && this.columns.some(column => column.orderable);
		}

		return false;
	}

	public get refreshShown(): boolean {
		return this.options.refreshShow !== undefined ? this.options.refreshShow : true;
	}

	public get resultsCountShown(): boolean {
		return this.options.resultsCountShow !== undefined ? this.options.resultsCountShow : true;
	}

	public get selectShow() {
		return (this.options as DataListOptions).select || false;
	}

	public get paginationPosition() {
		return this.options.paginationPosition ?? "bottom";
	}

	public resetSelection() {
		const elementsRemoved = [...this.elementsSelected];
		this.elementsSelected.splice(0, this.elementsSelected.length);
		this.$emit("select", elementsRemoved, false);
	}

	public findFilters(data: string) {
		return this.filters.find(f => f.data === data);
	}

	public resetFilters() {
		this.filters.forEach(filter => {
			filter.value = this.filtersInitialValue[filter.data];
		});
	}

	public async refresh(manually: boolean = false) {
		if (this.infiniteScroll) {
			this.start = 0;
		}

		await (DataListComponent as any).extendOptions.methods.refresh.call(this);

		if (manually === true) {
			this.$emit("refresh");
		}

		if (this.options.infiniteScroll) {
			await this.infiniteScrollTrigger();
		}
	}

	public clickRow(event: MouseEvent, element: T) {
		if (event.altKey && event.button === 0) {
			this.onSelectElement(element);
		} else if (this.options.callbackClickRow) {
			this.options.callbackClickRow(element, event);
			event.preventDefault();
		}
	}

	public getOrderClass(order: DataListColumn) {
		let orderClass = "fa-sort";

		if (order.dir === "asc") {
			orderClass = "fa-sort-up";
		} else if (order.dir === "desc") {
			orderClass = "fa-sort-down";
		}

		return orderClass;
	}

	public getRealIndex(index: number): number {
		return (this.currentPage - 1) * this.limit + index;
	}

	public isSelected(element: T) {
		if (this.loading) {
			return false;
		}

		const idElement = this.functionUniqueId(element);
		return this.elementsSelected.findIndex(e => this.functionUniqueId(e) === idElement) !== -1;
	}

	public isAllSelected() {
		if (this.loading || this.elements.length === 0) {
			return false;
		}

		return this.elements.every(element => {
			const idElement = this.functionUniqueId(element);

			return this.elementsSelected.findIndex(e => this.functionUniqueId(e) === idElement) !== -1;
		});
	}

	public onSelectAll(add: boolean) {
		for (const element of this.elements) {
			const idElement = this.functionUniqueId(element);
			const index = this.elementsSelected.findIndex(e => this.functionUniqueId(e) === idElement);

			if (index === -1 && add) {
				this.elementsSelected.push(element);
			} else if (!add) {
				this.elementsSelected.splice(index);
			}
		}
	}

	public onSelectElement(element: T) {
		const idElement = this.functionUniqueId(element);
		const index = this.elementsSelected.findIndex(e => this.functionUniqueId(e) === idElement);
		let selected = true;

		if (index === -1) {
			this.elementsSelected.push(element);
		} else {
			this.elementsSelected.splice(index, 1);
			selected = false;
		}

		this.$emit("select", [element], selected);
	}

	protected created() {
		this.onUpdateOptions(this.options);

		this.filters.forEach(filter => {
			if (_.isNull(this.filtersInitialValue[filter.data]) || _.isUndefined(this.filtersInitialValue[filter.data])) {
				this.filtersInitialValue[filter.data] = filter.value;
			}
		});
	}

	protected mounted() {
		this.configureInfiniteScroll();
	}

	protected activated() {
		if (this.overflowContainerElement && this.containerScrollTop) {
			this.overflowContainerElement.scrollTo(this.containerScrollLeft, this.containerScrollTop);
		}
	}

	protected deactivated() {
		if (this.overflowContainerElement) {
			this.containerScrollLeft = this.overflowContainerElement.scrollLeft;
			this.containerScrollTop = this.overflowContainerElement.scrollTop;
		} else {
			this.containerScrollLeft = 0;
			this.containerScrollTop = 0;
		}
	}

	private configureInfiniteScroll() {
		if (!this.options.infiniteScroll || !this.options.infiniteScrollAutoLoad) {
			return;
		}

		let overflowElement = null;
		let currentElement: HTMLElement | null = this.dataListContainer;

		while (overflowElement === null && currentElement != null) {
			const style = window.getComputedStyle(currentElement);

			if (style.overflow === "scroll" || style.overflowY === "scroll") {
				overflowElement = currentElement;
			} else {
				currentElement = currentElement.parentElement;
			}
		}

		if (currentElement) {
			this.overflowContainerElement = currentElement;
			this.overflowContainerElement.addEventListener("scroll", this.infiniteScrollTrigger);
		}
	}

	private async infiniteScrollTrigger() {
		if (
			!this.htmlElements ||
			(this.htmlElements && this.htmlElements.length === 0) ||
			this.loading ||
			!this.options.infiniteScrollAutoLoad
		) {
			return;
		}

		let htmlElementTrigger = this.htmlElements[this.htmlElements.length - 1];
		const rectOverflowElement = this.overflowContainerElement?.getClientRects()[0];

		if (this.htmlElements.length > this.limit * 0.5) {
			htmlElementTrigger = this.htmlElements[Math.ceil(this.htmlElements.length - this.limit * 0.5)];
		}

		if (
			!rectOverflowElement?.height ||
			!htmlElementTrigger?.getClientRects()[0] ||
			rectOverflowElement.height < htmlElementTrigger?.getClientRects()[0].y - rectOverflowElement.y ||
			this.currentPage * this.limit > this.elementsTotal
		) {
			return;
		}

		this.infiniteScrollLoadMore();
	}

	private async infiniteScrollLoadMore() {
		this.start += this.limit;

		this.loading = true;
		Array.prototype.push.apply(this.elements, (await this.retrieveData()).data);
		this.loading = false;
		this.$forceUpdate();
	}

	private destroyed() {
		if (this.options.infiniteScroll && this.overflowContainerElement != null) {
			this.overflowContainerElement.removeEventListener("scroll", this.infiniteScrollTrigger);
		}
	}

	@Watch("filters", { deep: true, immediate: false })
	private onUpdateFilter() {
		this.$emit("update-options", { ...this.options, filters: this.filters } as DataListOptions);
	}

	@Watch("options", { deep: true })
	private onUpdateOptions(options: DataListOptions) {
		const filtersShow = options.filtersShow !== undefined ? options.filtersShow : true;
		this.filtersShown = filtersShow && this.filters.length > 0 && this.filters.some(filter => filter.visible !== false);
	}

	@Watch("limit")
	private onUpdateLimit() {
		this.$emit("update-options", { ...this.options, displayLengthDefault: this.limit } as DataListOptions);
	}
}
